:root {
  --theme-blue: #3369f7;
  --text-gray: rgb(75, 75, 75);
  --light-gray: #7d7d7d;
  --slider-gray: #d3d3d3;
}

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
}

h1 {
  margin: 0;
  font-size: 28px;
  font-weight: 600;
}

h3 {
  margin: 0;
}

#header {
  grid-column: 1 / 5;
  grid-row: 1;

  padding: 15px 20px;
  background-color: var(--theme-blue);
  box-shadow: 0 3px 15px -2px rgba(0, 0, 0, 0.25),
    1px 1px 8px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;

  display: flex;
  align-items: baseline;
}
#header p {
  margin: 5px 0;
}

#title {
  color: #fff;
}
#intro {
  padding: 0px 20px;
  color: #cccccc;
  font-size: 16px;
}

@media (max-width: 700px) {
  #header {
    display: block;
    padding: 10px 10px;
  }
  #intro {
    padding: 0px 4px;
    font-size: 14px;
  }
}
@media (max-width: 370px) {
  #intro {
    font-size: 11.5px;
  }
}

.container {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 3px 6px -2px rgba(0, 0, 0, 0.2),
    1px 1px 8px 0px rgba(0, 0, 0, 0.16);
  color: var(--text-gray);
  margin: 8px;
  padding: 12px;
  overflow: hidden;
  position: relative;
}

.card-header {
  font-size: 24px;
}

.card-description {
  margin: 5px 0;
  color: var(--light-gray);
}

.content {
  grid-gap: 5px;
  margin: 0 12px;
}

/*Adjust padding and margins on mobile:*/
@media (max-width: 650px) {
  .container {
    padding: 8px;
  }
  .content {
    margin: 0;
  }
}

/* Start CSS Grid Section */
/*Mobile CSS*/
.content {
  display: grid;
  grid-template-columns: 1fr;
}
#cell-input {
  grid-column: 1;
  grid-row: 4;
}

#price-input {
  grid-column: 1;
  grid-row: 5;
}

#statistics {
  grid-column: 1;
  grid-row: 1;
  position: sticky;
  position: -webkit-sticky;
  top: 7px;
  z-index: 2;
}
#pie-section {
  grid-column: 1;
  grid-row: 2;
}
#bar-section {
  grid-column: 1;
  grid-row: 3;
}
#credit-section {
  grid-row: 6;
  grid-column: 1;
}

/*Tablet width section*/
@media (min-width: 700px) {
  .content {
    display: grid;
    grid-template-columns: 2fr 3fr;
  }

  #cell-input {
    grid-column: 1;
    grid-row: 1 / 4;
  }

  #price-input {
    grid-column: 1;
    grid-row: 4 / 5;
  }

  #statistics {
    grid-column: 2;
    grid-row: 1;
  }
  #pie-section {
    grid-column: 2 / 4;
    grid-row: 2;
  }
  #bar-section {
    grid-column: 2 / 4;
    grid-row: 3 / 6;
  }
  #credit-section {
    grid-row: 5;
    grid-column: 1;
  }
}

/*Medium width section*/
@media (min-width: 1025px) {
  .content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  #cell-input {
    grid-column: 1;
    grid-row: 1 / 3;
  }

  #price-input {
    grid-column: 1;
    grid-row: 3 / 4;
  }

  #statistics {
    grid-column: 2 / 4;
    grid-row: 1;
    position: sticky;
  }
  #pie-section {
    grid-column: 2;
    grid-row: 2 / 5;
  }
  #bar-section {
    grid-column: 3;
    grid-row: 2 / 5;
  }
  #credit-section {
    grid-row: 4;
    grid-column: 1;
  }
}

/*Max-width section*/
@media (min-width: 1500px) {
  .content {
    display: grid;
    grid-template-columns: 2fr 2fr 3fr 4fr;
    background-color: white;
  }

  #cell-input {
    grid-column: 1;
    grid-row: 1 / 3;
  }

  #price-input {
    grid-column: 2;
    grid-row: 1 / 3;
  }

  #statistics {
    grid-column: 3 / 5;
    grid-row: 1;
  }
  #pie-section {
    grid-column: 3;
    grid-row: 2 / 3;
  }
  #bar-section {
    grid-column: 4;
    grid-row: 2 / 3;
  }
  #credit-section {
    grid-row: 4;
    grid-column: 1;
  }
}
/*End CSS Grid Section

/*Start Statistics Section*/
/*Default values and tiny screen*/
#statistics {
  display: inline;
}
#statistics-header {
  padding-bottom: 3px;
}
#statistics-container {
  display: flex;
  flex-grow: 1;
  padding: 0px;
}
.stat-number {
  color: var(--theme-blue);
  font-size: 19px;
  font-weight: 700;
}
.stat-unit {
  color: var(--light-gray);
  font-size: 11px;
  margin: 3px;
}
.stat-caption {
  color: var(--light-gray);
  font-size: 13px;
}
.stat-item {
  flex-grow: 1;
  text-align: center;
}

/*Small screen Stats box*/
/*Only adjusting sizing*/
@media (min-width: 400px) {
  .stat-number {
    font-size: 25px;
  }
  .stat-unit {
    color: var(--light-gray);
    font-size: 13px;
    margin: 5px;
  }
  .stat-caption {
    font-size: 16px;
  }
}

/*Med and large screen stats box*/
@media (min-width: 1025px) {
  #statistics {
    display: flex;
  }
  #statistics-header {
    flex-grow: 0.12;
  }
  #statistics-container {
    display: flex;
    flex-grow: 1;
  }
  .stat-number {
    font-size: 30px;
    font-weight: 700;
  }
  .stat-unit {
    font-size: 14px;
    margin: 7px;
  }
  .stat-caption {
    font-size: 19px;
  }
  .stat-item {
    flex-grow: 1;
    text-align: center;
  }
}
/*End Statistics Section*/

/*Graph sizing*/
#pie-section,
#bar-section {
  height: calc(80vh - 50px);
  min-height: 400px;
}

@media (max-width: 1024px) {
  #pie-section,
  #bar-section {
    height: calc(55vh - 100px);
  }
}
/*End graph sizing*/
/*Start Graph Formatting*/
#pie-chart {
  height: 90%;
  padding: 5px;
  margin: 5px;
  box-sizing: border-box;
}

#bar-chart {
  height: 90%;
  padding: 5px;
  margin: 5px;
  box-sizing: border-box;
}
/*End Graph Formatting*/

/*Start Slider Section*/
.slider-section {
  display: flex;
  margin: 0 0 8px 0;
}

.slider {
  flex: 1 1 auto;
  width: 88%;
  margin: 8px 0;
  height: 4px;
  background: #cad9ff;
  transition: 0.2s ease all;
}
.slider::-moz-range-thumb {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: none;
  background: var(--theme-blue);
  cursor: pointer;
}
@media (pointer: coarse) {
  .slider::-moz-range-thumb,
  .slider::-webkit-slider-thumb {
    width: 18px;
    height: 18px;
  }
}
.slider::-moz-range-thumb:active {
  box-shadow: 0 0 0 8px #336af771;
}
/* .slider::-webkit-slider-thumb:active {
  box-shadow: 0 0 0 8px #336af771;
} 
This looks really bad in Chrome - shadow is a box not round*/
.slider::-moz-range-progress {
  background-color: var(--theme-blue);
}
.slider::-ms-fill-lower {
  background-color: var(--theme-blue);
}
.slider-value {
  flex-grow: 0 0 auto;
  width: 12%;
  margin: 0 6px;
  text-align: center;
  border: none;
  border-bottom: 1px solid #c0c0c0;
  -moz-appearance: textfield;
}
/* To hide arrows on Chrome:*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.slider-value:focus {
  border-bottom: 2px solid var(--theme-blue);
  transition: 0.05s ease all;
}

.slider-unit {
  font-size: 13px;
  color: var(--light-gray);
}

.slider-label-disabled {
  color: var(--light-gray);
  transition: 0.2s ease all;
}

.slider-disabled {
  -webkit-appearance: none;
  appearance: none;
}
.slider-disabled::-webkit-slider-thumb {
  -webkit-appearance: none;
}
.slider-disabled::-moz-range-thumb,
.slider-disabled::-webkit-slider-thumb {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: none;
  background: #a9a9a9;
  cursor: pointer;
}
@media (pointer: coarse) {
  .slider-disabled::-moz-range-thumb,
  .slider-disabled::-webkit-slider-thumb,
  .slider-webkit {
    width: 18px;
    height: 18px;
  }
}
.slider-disabled {
  background-color: var(--slider-gray);
}
.slider-disabled::-moz-range-progress {
  background-color: #a9a9a9;
}
.slider-disabled::-ms-fill-lower {
  background-color: #a9a9a9;
}
.slider-disabled::-moz-range-thumb:active {
  box-shadow: none;
}
.slider-value-disabled {
  color: #a9a9a9;
}

/* End Slider Section */

#credit-section {
  padding: 0px 7px;
}

.credit {
  margin: 0;
}
.icon {
  padding: 4px 0px;
}
